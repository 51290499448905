import { render, staticRenderFns } from "./Move.vue?vue&type=template&id=6240fa9d&"
import script from "./Move.vue?vue&type=script&lang=js&"
export * from "./Move.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "Move.vue"
export default component.exports